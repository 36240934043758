import React from "react"
import { ContactForm, Layout } from "../components"
import {
  Banner,
  PaddingWrapper,
  Content,
  already,
  Slider,
  BannerStyle,
} from "@igloonet-web/shared-ui"
import styled from "styled-components"

import ProfileAdam from "../images/team/profile/adam.jpg"
import ProfileLukas from "../images/team/profile/lukas.jpg"
import ProfileKepi from "../images/team/profile/kepi.jpg"
import ProfileSobol from "../images/team/profile/sobol.jpg"
import { Helmet } from "react-helmet"

const GrayWrapper = styled.div`
  background: ${(props) => props.theme.color.gray200};
`
const EcommercePoradenstvi: React.FC = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileAdam,
      name: "Adam Šilhan",
      position: `${already(2010)} zkušeností v\u00A0online marketingu`,
      text: "Google Certified Trainer oblasti Automation and Attribution, Exponea certifikace, hlavním záběrem je marketingová analytika, učí Webovou analytiku na Masarykově Univerzitě",
    },
    {
      id: 2,
      imageSrc: ProfileLukas,
      name: "Lukáš Havlík",
      position: `${already(2012)} zkušeností v\u00A0online marketingu`,
      text: "vede marketingový tým, garant pro sociální sítě, hlavním záběrem je komunikace a\u00A0kampaně na sociálních sítích, mentoruje a\u00A0školí klientské týmy",
    },
    {
      id: 3,
      imageSrc: ProfileKepi,
      name: "Ondra Kudlík",
      position: "20+ let zkušeností vývoj, hosting, devops",
      text: "řeší optimalizací větších e‑shopů a\u00A0webových aplikací; dává velký důraz na bezpečnost a\u00A0potenciální vendor lock; přednáší na Open Source konferencích",
    },
    {
      id: 4,
      imageSrc: ProfileSobol,
      name: "Jan Nobilis",
      position: "20+ let zkušeností vývoj, hosting, devops",
      text: "pomáhal klientům s\u00A0přebíráním zastaralých individuálních e‑shopů i\u00A0s\u00A0přechodem od krabicových řešení; má přesah z\u00A0čistě technické stránky do interních procesů e‑shopu a\u00A0jejich napojení na backend; funguje jako překladatel mezi klienty a\u00A0vývojáři",
    },
  ]

  return (
    <Layout>
      <Helmet>
        <title>E-commerce poradenství | igloonet</title>
        <meta
          name="description"
          content="Jste střední/větší e-shop a chcete hrát první ligu."
        />
      </Helmet>

      <Banner
        style={BannerStyle.Reference}
        orangeHeading="E-commerce<br/>poradenství"
        boldText
        text="Hrajte s&nbsp;námi v&nbsp;e&#8209;commerce první ligu"
      />

      <Content>
        <PaddingWrapper>
          <p>
            Za těch {already(2007)}, co jsou tučňáci tučňáky, jsme se
            u&nbsp;našich klientů setkali s&nbsp;lecčím –&nbsp;vstupem nových
            e‑shopů na trh, expanzemi do zahraničí i&nbsp;globálními kampaněmi.
            Máme tak docela dobrou představu, kdy a&nbsp;proč to bude fungovat,
            kdy ne a&nbsp;kdy můžete letět tak blízko ke Slunci, že projekt
            shoří.
          </p>
          <p>
            Rádi vám předáme naše zkušenosti. Samotnou exekutivní práci (správa
            kampaní, stavění webů) děláme rádi dál, ale čím dál častěji pomáháme
            firmám na více strategické úrovni.
          </p>
        </PaddingWrapper>
      </Content>

      <GrayWrapper>
        <Content>
          <PaddingWrapper>
            <h2>
              Oblasti, které nás nejvíc baví a&nbsp;kde pomůžeme nejraději:
            </h2>
            <ul>
              <li>
                <strong>mentoring a&nbsp;stavění interních týmů:</strong>{" "}
                probereme, kdy dává smysl začít tým stavět, koho v&nbsp;něm
                budete potřebovat, jak je správně najít a&nbsp;jak je dál
                rozvíjet;
              </li>
              <li>
                <strong>připravenost e‑shopu a&nbsp;infrastruktura:</strong>{" "}
                nevíte, jestli váš e‑shop na míru už není technologicky
                zastaralý a&nbsp;nebude pro vás lepší jít do krabice, nebo máte
                naopak pocit, že je vám krabice už těsná?
              </li>
              <li>
                <strong>
                  marketingová strategie, optimalizace marketingových taktik:
                </strong>{" "}
                máte pocit, že jste narazili na strop, že nerostete tak rychle,
                jak by se vám líbilo, snažíte se dohnat brand debt, nebo jste se
                dostali do fáze, kdy chcete postupně zvyšovat zisky?
              </li>
              <li>
                <strong>
                  automatizace (primárně marketingová, ale i&nbsp;např. práce
                  s&nbsp;e‑shopem, skladem, s&nbsp;objednávkami):
                </strong>{" "}
                netrvají vám některé činnosti zbytečně dlouho, když jdou dělat
                strojově?
              </li>
              <li>
                <strong>pohled zvenčí:</strong> e‑shop často nejvíc potřebuje
                pohled někoho, kdo danou situaci už párkrát řešil, a&nbsp;přitom
                nemá tunelové vidění.
              </li>
            </ul>
          </PaddingWrapper>

          <h2>
            Nejčastěji pracujeme s&nbsp;e‑shopy, ale poradíme i&nbsp;jinde
          </h2>
          <ul>
            <li>
              Pro T‑Mobile jsme připravili odbornou část tendru na online
              agenturu;
            </li>
            <li>
              Pro Study in Brno jsme vytvořili sérii školení na míru, dál
              mentorujeme interní tým.
            </li>
          </ul>
          <h2>Zkušenosti budujeme napříč oblastmi:</h2>
          <ol>
            <li>marketing,</li>
            <li>data,</li>
            <li>vývoj a technologie,</li>
            <li>infrastruktura.</li>
          </ol>
        </Content>
      </GrayWrapper>

      <Slider title={"Poznejte náš tým"} data={personSlider} />

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default EcommercePoradenstvi
